import React from "react"
import { Link } from "gatsby"
import SEO from "../../components/seo"

function Index() {
  return (
    <>
      <SEO title="Sign Up | Omniplex Learning" />
      <div class="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div class="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            class="mx-auto h-16 w-auto"
            src="https://omniplexlearning.com/wp-content/themes/Omniplex/image/omni-logo-dark.svg"
            alt="Omniplex"
          />
          <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Create A New Account
          </h2>
          <p className="mv-4 text-center text-gray-900">
            Fill out the fields below.
          </p>
        </div>

        <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form class="space-y-6" action="#" method="POST">
              <div>
                <label
                  for="email"
                  class="block text-sm font-medium text-gray-700"
                >
                  Email address
                </label>
                <div class="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autocomplete="off"
                    class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <label
                  for="division"
                  class="block text-sm font-medium text-gray-700"
                >
                  Division ID
                </label>
                <div class="mt-1">
                  <input
                    id="division"
                    name="division"
                    autocomplete="off"
                    class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <label
                  for="employee"
                  class="block text-sm font-medium text-gray-700"
                >
                  Employee ID
                </label>
                <div class="mt-1">
                  <input
                    id="employee"
                    name="employee"
                    autocomplete="off"
                    class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <label
                  for="password"
                  class="block text-sm font-medium text-gray-700"
                >
                  Password
                </label>
                <div class="mt-1">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autocomplete="off"
                    class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <label
                  for="password"
                  class="block text-sm font-medium text-gray-700"
                >
                  Password Confirm
                </label>
                <div class="mt-1">
                  <input
                    id="passwordC"
                    name="passwordC"
                    type="password"
                    autocomplete="off"
                    class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <Link
                  to="/omniplex-sandbox/person/"
                  class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-900 hover:bg-amber-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                >
                  Create an Account
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
      <footer class="bg-white">
        <div class="max-w-7xl mx-auto py-8 px-4 overflow-hidden sm:px-6 lg:px-8">
          <p class="mt-8 text-center text-base text-gray-400">
            This UI mockup is by the fantastic team at{" "}
            <a
              href="https://tailwindui.com/"
              class=" text-gray-900"
              target="_blank"
            >
              TailwindUI
            </a>
          </p>
        </div>
      </footer>
    </>
  )
}

export default Index
